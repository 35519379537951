.aside-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  overflow: auto;
  border-right: 1px solid #e0e0e0;
  .p1 {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
    font-weight: bolder;
    user-select: none;
  }
  .lib-sub {
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .MuiButton-startIcon{
      color: #07689f;
    }
  }
  .aside-bar-container {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  .playlist{
    flex: 1;
    .p1{
      border-top: 1px solid #e0e0e0;
    }
    .lib-sub:hover{
      background-color: #a2d5f2;
    }
  }
}

@media only screen and (max-width: 970px) {
  .aside-bar {
    display: none;
  }
}