*{
  box-sizing: border-box;
}
body{
  font-family: Consolas,sans-serif;
  padding: 0;
  margin: 0;
/*  background-color: #e0e0e0;*/
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
button{
  text-transform: capitalize !important;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #07689f;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a2d5f2;
}
