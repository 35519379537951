.Footer_Select_Music {
  width: 100%;
  height: 10vh;
  a{
    color: #444;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: Consolas,sans-serif;
    font-size: 1.3rem;
    line-height: 10vh;
    padding: 2px;
    display: block;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 445px) {
  .Footer_Select_Music{
    a{
      line-height: 5vh;
    }
  }
}