@mixin flexBox() {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

nav {
  height: 10vh;
  display: flex;
  background-color: #f6f6f6;
  user-select: none;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 10px;

  div {
    @include flexBox;
  }

  .navigation {
    flex-flow: row wrap;
  }

  .language {
    padding: 10px;
  }

  .Dropdown-btn {
    display: flex;
    .wrapper {
      padding: 0;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 970px) {
  nav {
    display: none;
  }
}