#main {
  font-family: Consolas, sans-serif;
  background: #794141;
  background: linear-gradient(110deg, #6aa5d9 40%, #a5d96a 60%);
  height: 100vh;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  .nav-item {
    height: 10vh;
    display: flex;
    font-size: 1.3rem;
    .navbar-brand {
      font-family: "Pacifico", cursive;
      color: #f8eeee;
      font-size: 3rem;
    }
  }
  .main-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 90vh;
    .main-row-img{
      flex-basis: 50%;
      flex-grow: 1;
      .head-phone-img {
        width: 70%;
      }
    }
    .main-row-text{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      flex-basis: 50%;
      flex-grow: 1;
      h1 {
        font-family: "Bungee", cursive;
        font-size: 3rem;
      }
      p {
        color: #f8eeee;
        font-size: 1.2rem;
      }
      .btn{
        font-family: Consolas, sans-serif;
        letter-spacing: 2px;
        border: none;
        background-color: #282c34;
        color: #fff;
        font-size: 1.1rem;
        text-transform: uppercase;
        margin: 10px 0;
        padding: 15px 20px;
        border-radius: 5px;
      }
      .btn:hover{
        opacity: 0.9;
        cursor: pointer;
      }
    }
  }
}
