.music-card-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
@media only screen and (max-width: 970px) {
  .music-card-container {
    height: 70vh;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}